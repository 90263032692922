import React, { useCallback, useState } from 'react';
import cls from 'classnames';

import Fade from 'components/Fade';
import VideoPlayer from 'components/VideoPlayer';

import styles from './style.module.less';

export default ({
  title,
  description,
  content,
  bgColor = 'light',
  src,
  itemStyle,
  video,
  style,
}) => {
  const [show, setShow] = useState(false); // 是否展示视频播放器
  const [url, setUrl] = useState(''); // 当前视频播放地址
  // handler
  const playHandler = useCallback((video) => {
    setUrl(video);
    setShow(true);
  }, []);
  const closeHandler = useCallback(() => {
    setUrl('');
    setShow(false);
  }, []);

  return (
    <div
      id="teacher-gallery"
      className={cls([styles.container, bgColor])}
      style={style}
    >
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{title}</h2>
        {description && <p className={styles.desc}>{description}</p>}

        <div className={styles.gallery}>
          <img className={styles.galleryImg} src={src} style={itemStyle} />

          {video && (
            <div className={styles.galleryContent}>
              <div
                className={styles.galleryVideoIcon}
                onClick={() => playHandler(video)}
              >
                <img
                  className={styles.galleryVideoIconImg}
                  style={{ width: 68 }}
                  src={require('images/icon-play.png')}
                />
              </div>
              <p className={styles.galleryVideoDesc}>讲师展示视频</p>
            </div>
          )}
        </div>

        <div className={styles.infos}>
          {(content || []).map(({ title, desc }, i) => (
            <div key={i} className={styles.info}>
              <span className={styles.infoTitle}>{title}</span>
              <span className={styles.infoDesc}>{desc}</span>
            </div>
          ))}
        </div>
      </div>

      <Fade in={show}>
        <VideoPlayer src={url} onClose={closeHandler} />
      </Fade>
    </div>
  );
};
