import React from 'react';
import { useSwipeable } from 'react-swipeable';
import { TransitionGroup } from 'react-transition-group';
import cls from 'classnames';

import Fade from 'components/Fade';
import useLoop from 'utils/useLoop';

import styles from './style.module.less';

export default ({ list }) => {
  const len = (list || []).length;
  const [curr, { goto, next, prev, pause }] = useLoop(0, { totalSize: len });
  const handler = useSwipeable({
    onSwipedLeft: next,
    onSwipedRight: prev,
    onSwiping: pause,
    preventDefaultTouchmoveEvent: true,
  });

  return (
    <div {...handler} className={styles.container}>
      <TransitionGroup className={styles.slides}>
        {(list || [])
          .filter((_, i) => i === curr)
          .map(({ src, style, title, desc }) => {
            return (
              <Fade key={src}>
                <div className={styles.slide}>
                  <div className={styles.slideImg}>
                    <div className={styles.clearBlock} />
                    <img src={src} style={style} />
                  </div>
                  <div className={styles.slideMask} />
                  <div className={styles.slideContent}>
                    <div className={styles.slideTitle}>{title}</div>
                    <div className={styles.slideDesc}>{desc}</div>
                  </div>
                </div>
              </Fade>
            );
          })}
      </TransitionGroup>

      <div className={styles.dots}>
        {(list || []).map((__, i) => (
          <div
            key={i}
            className={cls([styles.dot, curr === i && 'active'])}
            onClick={() => goto(i)}
          />
        ))}
      </div>
    </div>
  );
};
