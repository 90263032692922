import React, { useCallback, useMemo, useState } from 'react';
import ImageViewer from 'components/ImageViewer';
import jump from 'utils/jump';

import styles from './style.module.less';

export default ({ title, description, pictures, style }) => {
  const [curr, setCurr] = useState(null);
  const imagelist = useMemo(
    () =>
      pictures.map(({ original, desc }) => ({
        src: original.src,
        title: desc,
      })),
    [pictures]
  );
  const closeHandler = useCallback(() => setCurr(null), []);

  return (
    <div id="activity-env" className={styles.container} style={style}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.desc}>{description}</p>

        <div className={styles.gallery}>
          {(pictures || []).map(({ thumbnail, title }, i) => {
            return (
              <div key={i} className={styles.galleryPreviewWrapper}>
                <span
                  className={styles.galleryPreview}
                  onClick={() => {
                    jump('#activity-env');
                    setCurr(i);
                  }}
                >
                  <img
                    className={styles.galleryPic}
                    src={thumbnail.src}
                    style={thumbnail.itemStyle}
                  />
                </span>
                <div className={styles.galleryPreviewTitle}>{title}</div>
              </div>
            );
          })}
        </div>

        <ImageViewer
          visible={curr !== null}
          index={curr}
          imgs={imagelist}
          onClose={closeHandler}
        />
      </div>
    </div>
  );
};
